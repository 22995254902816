<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol == 'empleado' || rol == 'admin-depto'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Menú de las cocinas'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                            <v-menu offset-y content-class="menuClassAcciones ">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones 
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                    
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div @click="abrirModalLayout('importar')" class="btnAccionesItem py-2">
                                        <span>Importar layout de platillos</span><br />
                                    </div>
                                    <div @click="(rol == 'root' || rol =='admin') ? abrirModalLayout('exportar'):descargarLayout()" class="btnAccionesItem py-2">
                                        <span>Descargar layout de platillos</span>
                                    </div>
                                </div>
                            </v-menu>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol  == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="cocinas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cocinas"
                                    persistent-hint
                                    v-model="cocina_value"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    :items="tiposBusqueda"
                                    item-text="nombre"
                                    item-value="valor"
                                    label="Tipo"
                                    persistent-hint
                                    v-model="tipoBusqueda"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-text-field 
                                    outlined 
                                    label="Nombre del guiso" 
                                    v-model="filterNombre"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    label="Fecha mínima"
                                    :valorDefecto="fecha_min"
                                    v-model="fecha_min"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaMinima"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    label="Fecha máxima"
                                    :valorDefecto="fecha_max"
                                    v-model="fecha_max"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaMaxima"
                                />
                            </v-col>
                            

                        </template>


                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.cocina.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.fecha == null ? 'NA': dateFormat(item.fecha)}}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.tipo=='fijo' ? 'Fijo':'Diario' }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">

                                        <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassOpciones"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                    @click="abrirModalDuplicar(item)"
                                                    v-if="item.tipo == 'diario'"
                                                >
                                                    <v-list-item-title>Duplicar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="abrirModal('update', item)" 
                                                >
                                                    <v-list-item-title>Editar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="eliminar(item)"  
                                                    v-if="permisos.delete"
                                                >
                                                    <v-list-item-title>Eliminar</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>


                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template>
                <v-dialog v-model="dialog" persistent max-width="750px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <!-- <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title> -->

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-row class="my-5">
                                        <template v-if="accion == 'add'">

                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Cliente"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Empresa"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cocina" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="cocinas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Cocinas"
                                                        persistent-hint
                                                        v-model="cocina_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                        </template>
                                        <template v-if="accion=='update'">
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                    outlined
                                                    label="Cocina" 
                                                    class="pa-0 ma-0" 
                                                    v-model="nombreCocina" 
                                                    readonly
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </template>
                                        
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Nombre" 
                                                    class="pa-0 ma-0" 
                                                    v-model="guiso.nombre" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <v-text-field 
                                                outlined
                                                label="Descripción" 
                                                class="pa-0 ma-0" 
                                                v-model="guiso.descripcion" 
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Tipo" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="tipos"
                                                    item-text="nombre"
                                                    item-value="valor"
                                                    label="Tipo"
                                                    persistent-hint
                                                    v-model="guiso.tipo"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="guiso.tipo == 'diario'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha"
                                                :valorDefecto="guiso.fecha"
                                                v-model="guiso.fecha"
                                                placeholder="dd/mm/aaaa"
                                                ref="Fecha"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyMoneyValidate
                                                rules="required"
                                                outlined
                                                label="Precio empleado"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="guiso.precio" 
                                                v-bind:options="options"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyMoneyValidate
                                                rules="required"
                                                outlined
                                                label="Precio empresa"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="guiso.precio_empresa" 
                                                v-bind:options="options"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar imagen"
                                                v-model="archivo"
                                                accept="image/*"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                            <v-img
                                                min-height="200"
                                                max-height="250"
                                                max-width="250" 
                                                lazy-src="/static/modal/platillo_comida.png"
                                                :src="imageUrl" 
                                                style=""
                                                :key="keyImg"
                                            />
                                        </v-col>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                    v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>

                            <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                >
                                    El usuario no tiene permiso para editar el registro.
                                </v-alert>
                            </div>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogLayout" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayout"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formLayout" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloLayout }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Cliente"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Empresa"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="accionLayout === 'importar'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivoLayout"
                                                    accept=".xlsx"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'importar'" @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'exportar'" @click="descargarLayout()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>


            <template>
                <v-dialog v-model="dialogDuplicar" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formDuplicar" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-if="isLoading" class="d-flex justify-center">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-if="!isLoading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha"
                                                :valorDefecto="fechaDuplicar"
                                                v-model="fechaDuplicar"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaDuplicar"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" @click="duplicarGuiso()" :disabled="invalid" :loading="isSaving">Duplicar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
/* API's */
import apiGuisos from "@/api/comidas/guisos";
import apiCocinas from "@/api/comidas/cocinas";
import apiClientes from '@/api/clientes';
import apiEmpresas from '@/api/empresas';

/* COMPONENTES */
import Datatable from "@/components/datatable/Datatable.vue";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';

//PLUGINS
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        "data-table": Datatable,
        VuetifyDatePicker,
        VuetifyMoneyValidate,
    },
    data() {
        return {
            datosLogin  : null,
            rol         : null,
            url         : "comida-guiso/find",
            columns: [
                {
                    label: "Nombre",
                    name: "nombre",
                    filterable: true,
                },
                {
                    label: "Cocina",
                    name: "cocina",
                    filterable: false,
                },
                {
                    label: "Fecha",
                    name: "fecha",
                    filterable: true,
                },
                {
                    label: "Tipo",
                    name: "tipo",
                    filterable: true,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    filterable: false,
                    align: 'center'
                },
            ],
            filters: {
                activo      : true,
                paginate    : true,
                order_by    : 'fecha',
                cliente_id  : null,
                empresa_id  : null,
                sucursal_id : null,
                sucursales  : [],
                nombre      : null,
                tipo        : null,
                cocina_id   : null,
                fecha_min   : null,
                fecha_max   : null,
            },
            guiso: {
                id          : null,
                cliente_id  : null,
                empresa_id  : null,
                nombre      : null,
                descripcion : null,
                cocina_id   : null,
                tipo        : null,
                fecha       : null,
                precio      : null,
                precio_empresa: null,
            },
            tipos           :[
                {
                    nombre:'Fijo',
                    valor:'fijo'
                },
                {
                    nombre:'Diario',
                    valor:'diario'
                }
            ],
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            tituloModal     : '',
            imagen          : '',
            accion          : '',
            isLoadingModal  : false,
            isLoading       : false,
            dialog          : false,
            isSaving        : false,
            cliente_value   : null,
            empresa_value   : null,
            cocina_value    : null,
            clientes        : [],
            empresas        : [],
            cocinas         : [],
            nombreCocina    : '',
            archivo         : undefined,
            filterNombre    : null,
            imageUrl        : "",
            keyImg          : 0,
            urlImg          : GetUrlImg.getURL(),
            dialogLayout    : false,
            tituloLayout    : '',
            archivoLayout   : null,
            accionLayout    : '',
            tiposBusqueda : [
                {
                    nombre: "Todos",
                    valor: "todos"
                },
                {
                    nombre:'Fijo',
                    valor:'fijo'
                },
                {
                    nombre:'Diario',
                    valor:'diario'
                }
            ],
            tipoBusqueda    : 'todos',
            fechaDuplicar   : null,
            dialogDuplicar  : false,
            guiso_id        : null,
            fecha_min       : null,
            fecha_max       : null,

            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        }
    },
    watch: {
        cliente_value: function(val) {
            if(val != null){
                this.getEmpresas(val);
            }
        },
        empresa_value: function(val) {
            this.cocina_value = null;
            if(val != null){
                this.getCocinas(val);
            }
        },
        archivo: function(val){
            //this.keyImg = this.keyImg + 1;
            if(val != null && val != undefined){
                this.onFileChange(val)
            }
            if(val == null || val == undefined){
                this.imageUrl = "/static/modal/platillo_comida.png"
            }
        },
    },
    methods: {
        open(){
            document.getElementById("fileDocument").click();
        },
        createImage(file) {
            const reader = new FileReader();

            reader.onload = e => {
                this.imageUrl = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        onFileChange(file) {
            if (!file) {
                return;
            }
            this.createImage(file);
        },
        async abrirModal(accion, data = null){
            await this.resetValues()
            this.accion         = accion;
            this.isLoadingModal = true;

            if(accion == "add"){
                this.tituloModal    = "Agregar nuevo platillo al menú";
                this.imagen         = "/static/modal/brigadasCreate.svg";
                if(this.rol == 'root'){
                    await this.getClientes();
                }
                else if(this.rol == 'admin'){
                    this.cliente_value = this.datosLogin.cliente_id;
                }
                else if(this.rol == 'admin-empresa'){
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }
                else if(this.rol == 'admin-sucursal'){
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.getCocinas()
                }
                else {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }
                
            } else {
                this.tituloModal        = "Editar platillo";
                this.imagen             = "/static/modal/brigadasUpdate.svg";
                this.guiso.id           = data.id;
                this.guiso.nombre       = data.nombre;
                this.guiso.descripcion  = data.descripcion;
                this.guiso.tipo         = data.tipo;
                this.guiso.fecha        = data.fecha;
                this.guiso.precio       = data.precio;
                this.guiso.precio_empresa = data.precio_empresa;
                this.cocina_value       = data.cocina_id;
                this.nombreCocina       = data.cocina.nombre;
                this.imageUrl           = this.urlImg + data.foto

            }
            this.isLoadingModal  = false;
            this.dialog = true;
        },
        guardar(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                this.guiso.cliente_id   = this.cliente_value;
                this.guiso.empresa_id   = this.empresa_value;
                this.guiso.cocina_id    = this.cocina_value;

                if(this.guiso.tipo == 'fijo'){
                    this.guiso.fecha = null
                }


                let formData = new FormData();
                formData.append("cocina_id", this.guiso.cocina_id);
                formData.append("nombre", this.guiso.nombre);
                formData.append("descripcion",this.guiso.descripcion)
                formData.append("tipo", this.guiso.tipo);
                formData.append("fecha", this.guiso.fecha ? this.guiso.fecha : '');
                formData.append("precio", this.guiso.precio);
                formData.append("precio_empresa", this.guiso.precio_empresa);

                if(this.archivo != null){
                    formData.append("foto", this.archivo);
                }

                this.isLoading = true;
                if(this.accion === "add") {

                    apiGuisos.store(formData).then((response) => {
                        Notify.Success("Operación exitosa", "El guiso se guardó satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    formData.append("id", this.guiso.id);

                    apiGuisos.update(formData).then((response) => {
                        Notify.Success("Operación exitosa", "El guiso se actualizó satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el guiso: " + data.nombre + "?",
                "Eliminar",
                () => {
                    apiGuisos.delete(data.id).then(response => {
                        Notify.Success("Guiso eliminado", "El guiso ha sido eliminado satisfactoriamente");
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        async cerrarModal(){
            this.dialog     = false;
            this.dialogLayout = false;
            this.dialogDuplicar = false;
            this.isSaving   = false;
            await this.resetValues();
        },
        async resetValues() {
            this.guiso = {
                id          : null,
                cliente_id  : null,
                empresa_id  : null,
                nombre      : null,
                descripcion : null,
                cocina_id   : null,
                tipo        : null,
                fecha       : null,
                precio      : null,
                precio_empresa: null,
            };
            this.cocinas        = [];
            this.cocina_value   = null;
            this.archivo        = undefined;
            this.filterNombre   = null;
            this.imageUrl        = "";

            this.tipoBusqueda = 'todos';

            this.archivoLayout = null;
            this.tituloLayout    = '';
            this.accionLayout    = '';

            this.fechaDuplicar  = null;
            this.guiso_id       = null;

            this.fecha_min      = null;
            this.fecha_max      = null;
            this.isLoading      = false;

            this.imageUrl = "/static/modal/platillo_comida.png"

            if(this.rol == 'root'){
                this.getClientes();
                this.cliente_value  = null;
                this.empresa_value  = null;
                this.empresas = [];
            }
            else if(this.rol == 'admin'){
                this.cliente_value  = null;
                this.empresa_value = null;
                this.empresas = [];
                
            }
            else if(this.rol == 'admin-empresa'){
                this.cliente_value  = null;
                this.empresa_value = null;
            }
            else {
                
            }

            this.$nextTick(() => {
                this.imageUrl        = "";
            });

            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }

                if(this.$refs.formLayout){
                    this.$refs.formLayout.reset();
                }

                if(this.$refs.formDuplicar){
                    this.$refs.formDuplicar.reset();
                }
            });
        },
        async loadModalData(){
            await this.resetValues()
            if(this.rol == 'root'){
                await this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.getCocinas()
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        async setFilters(){
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let sucursal = null;
            let nombre = this.filterNombre;
            let cocina = this.cocina_value;
            let tipo = this.tipoBusqueda;
            let fecha_min = this.fecha_min;
            let fecha_max = this.fecha_max;

            if(this.rol == 'root'){

            }
            else if (this.rol == "admin") {    
                cliente = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            }
            else if (this.rol == 'admin-sucursal'){
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
            }
            else{
                cliente = this.datosLogin.cliente_id;
                empresa= this.datosLogin.empresa_id;
            }

            if(cocina != null){
                this.filters.cocina_id = cocina;
            }
            else{
                this.filters.cocina_id = null
            }

            if(tipo == 'diario'){
                this.filters.tipo = tipo
            }
            else if(tipo == 'fijo'){
                this.filters.tipo = tipo
            }
            else{
                this.filters.tipo = null
            }

            this.filters.cliente_id     = cliente;
            this.filters.empresa_id     = empresa;
            this.filters.sucursal_id    = sucursal;
            this.filters.nombre         = nombre;
            this.filters.fecha_min      = fecha_min;
            this.filters.fecha_max      = fecha_max;


            await this.$refs.tabla.getData();
            await this.resetValues();
        },
        async getClientes(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre'
            }
            
            await apiClientes.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        getEmpresas(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value
            }
            apiEmpresas.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        getCocinas(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value,
                sucursal_id : null,
            }

            if(this.rol == 'admin-sucursal'){
                parametros.sucursal_id = this.datosLogin.sucursal_id;
            }

            apiCocinas.find(parametros).then((response) => {
                this.cocinas = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las cocinas.");
            });
        },

        async abrirModalLayout(accionLayout = ''){
            this.accionLayout = accionLayout
            if(accionLayout == 'importar'){
                this.tituloLayout = "Importar layout de platillos"
            }
            else if(accionLayout == 'exportar'){
                this.tituloLayout = "Exportar layout de platillos"
            }

            if(this.rol == 'root'){
                await this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

            this.dialogLayout = true;
        },
        async descargarLayout(){

            this.isSaving = true;
            let self = this;
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre = '';

            nombre = "layout_platillos_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".xlsx";
            
            let parametros = {
                cliente_id: null,
                empresa_id: null, 
                sucursal_id: null,
            }

            if(this.rol == 'root'){
                parametros.cliente_id = this.cliente_value;
                parametros.empresa_id = this.empresa_value;
            }
            else if(this.rol == 'admin'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.empresa_value;
            }
            else if(this.rol == 'admin-empresa'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
            }
            else {
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
                parametros.sucursal_id = this.datosLogin.sucursal_id;
            }

            try {
                await apiGuisos.descargarLayout(parametros)
                .then((response) => {
                    this.isSaving = false;
                    FileDownload(response.data, nombre);
                    this.cerrarModal();
                })
                .catch(async (error)=>{
                    self.isSaving = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                    
                });
            } catch (e) {
                self.isSaving = false;
                Notify.ErrorToast("Se produjo un error al realizar la petición.");
            }
        },
        
        guardarLayout(){
            let formData = new FormData();

            if(this.rol == 'root'){
                formData.append("cliente_id", this.cliente_value);
                formData.append("empresa_id", this.empresa_value);
            }
            else if(this.rol == 'admin'){
                formData.append("cliente_id", this.datosLogin.cliente_id);
                formData.append("empresa_id", this.empresa_value);
            }
            else {
                formData.append("cliente_id", this.datosLogin.cliente_id);
                formData.append("empresa_id", this.datosLogin.empresa_id);
            }

            formData.append("archivo", this.archivoLayout);

            this.isSaving = true;
            apiGuisos.guardarLayout(formData).then((response) => {
                Notify.Success("Operación exitosa", "La petición se realizó satisfactoriamente.");
                if(response.status == 200){
                    Notify.Success("Operación exitosa", "La petición se realizó satisfactoriamente.");
                }
                else if(response == 202){
                    Notify.Success("Operación con errores", "La petición se realizó tuvo errores.");
                    let errores = response.data.error /*************  Pendiente  **************/
                }


                this.$refs.tabla.getData();
                this.cerrarModal();
            })
            .catch(err => {
                this.isLoading = false;
                this.isSaving = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },

        listar(){
            if(this.rol == 'root'){
                this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.datosLogin.sucursal_id;
            }
            else {
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        dateFormat(fecha, tipo) {
            if(!fecha) return
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        abrirModalDuplicar(item){
            this.dialogDuplicar = true;
            this.tituloModal    = "Duplicar platillo";
            this.guiso_id       = item.id;
            this.isLoading      = false;
        },
        duplicarGuiso(){
            this.$refs.formDuplicar.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let parametros = {
                    guiso_id        : this.guiso_id,
                    fecha   : this.fechaDuplicar,
                }

                this.isLoading = true;

                apiGuisos.duplicar(parametros).then((response) => {
                    Notify.Success("Operación exitosa", "El guiso se guardó satisfactoriamente.");
                    this.$refs.tabla.getData();
                    this.cerrarModal();
                })
                .catch((err) => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al realizar la petición.");
                });
            })
        },

        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("comida-guiso.store")
            this.permisos.update = permisosStorage.includes("comida-guiso.update")
            this.permisos.delete = permisosStorage.includes("comida-guiso.delete")
        }
    },
    created() {
        this.datosLogin = this.$session.get('usuario');
        this.rol        = this.datosLogin.rol.name;
        this.listar();
        this.permisosVista();
    },
}
</script>
<style scoped>
    #btnModalLayout {
        display: none;
    }
    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #FFF;
    }
    .btnAddContenedor {
        width: 250px;
        height: 140px;
        padding-top: 30px;
        background-color: #FFF;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .menuClassOpciones{
        border-radius: 10px!important;
    }
</style>